import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import "../vendor/perfect-scrollbar.css";
import "../ckeditor/ck-content.css";

import {
    Typography,
    Box,
    Paper,
    Tooltip
} from '@material-ui/core';

import {
    CloudUpload as CloudUploadIcon
} from "@material-ui/icons";

const ImageBlock = ({ imageKey, onClick, accountImages, innerRef }) => {

    const [position, setPosition] = useState({ x: undefined, y: undefined });

    const accountImage = useMemo(() => {
        return accountImages[imageKey];
    }, [accountImages, imageKey]);

    const imageDataUrl = useMemo(() => {
        return accountImages == null ? null : accountImages[imageKey]?.imageDataUrl;
    }, [accountImages, imageKey]);

    return (
        <Paper style={{ marginBottom: '10px' }}>
            <Box display="flex" justifyContent="center" style={{ cursor: 'pointer' }} onClick={onClick}>
                {
                    accountImage &&
                    <Tooltip
                        title="Click to edit"
                        onMouseMove={e => setPosition({ x: e.pageX, y: e.pageY })}
                        PopperProps={{
                            anchorEl: {
                                clientHeight: 0,
                                clientWidth: 0,
                                getBoundingClientRect: () => ({
                                    top: position.y,
                                    left: position.x,
                                    right: position.x,
                                    bottom: position.y,
                                    width: 0,
                                    height: 0,
                                })
                            }
                        }}
                    >
                        {
                            accountImage.imageDataUrl
                                ? <img style={{ maxWidth: '100%', maxHeight: '150px' }} src={imageDataUrl} />
                                : <div ref={innerRef} className="ck-content" style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: accountImage.htmlContent }}></div>
                        }
                    </Tooltip>
                }
                {
                    !accountImage &&
                    <Box display="flex" alignItems="center">
                        <CloudUploadIcon style={{ marginRight: '5px' }} />
                        <Typography >No {imageKey} uploaded.  Click here to upload.</Typography>
                    </Box>
                }
            </Box>
        </Paper>
    );
}

const mapStateToProps = state => ({
    accountImages: state.configReducer.accountImages
});

export default connect(mapStateToProps)(ImageBlock);



