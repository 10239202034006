import React, { useState, useCallback, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import axios from "../plugins/axios";
import axios2 from "axios";
import { setSnackAction } from "../redux/actions/snackActions";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Typography,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
} from "@material-ui/core";
import {
  CloudUpload,
  Image,
  Videocam,
  InsertDriveFile,
} from "@material-ui/icons";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
`;

const fileIcons = {
  image: <Image />,
  video: <Videocam />,
  other: <InsertDriveFile />,
};

const displayNthFile = (fileArray, bytes) => {
  let bytesCount = 0;
  fileArray.forEach((f, index) => {
    bytesCount += f.size;
    if (bytesCount >= bytes) return index + 1;
  });
  return fileArray.length;
};

const useQuery = () => new URLSearchParams(useLocation().search);

const apiUrl = process.env.REACT_APP_LDM_API_URL;

const EvidenceUploader = (props) => {
  const queryParams = useQuery();
  const { caseGuid } = useParams();
  const history = useHistory();
  const employees = queryParams.getAll("employee");
  const [caseId, setCaseId] = useState(0);
  const [uploadedBytes, setUploadedBytes] = useState(null);
  const uploadedBytesRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [source, setSource] = useState(axios2.CancelToken.source());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchCaseIdForGuid(caseGuid) {
      try {
        const { data: caseId } = await axios.get(
          `${apiUrl}/case/getcaseidbyguid/${caseGuid}`
        );
        setCaseId(caseId);
      } catch (e) {
        console.error(e);
        history.push("/");
      } finally {
        setIsLoading(false);
      }
    }

    if (!caseGuid) {
      console.error("No case guid!");
      history.push("/");
      return;
    }

    fetchCaseIdForGuid(caseGuid);
  }, [caseGuid]);

  const onDrop = useCallback((acceptedFiles) => {
    if (uploadedBytesRef.current) {
      setUploadComplete(false);
      setUploadedBytes(null);
      setTotalSize(null);
      setFiles(acceptedFiles);
    } else setFiles((f) => [...f, ...acceptedFiles]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    disabled: uploadedBytes && !uploadComplete,
  });

  const fileList = files.map((file) => (
    <ListItem key={file.path}>
      <ListItemAvatar>
        <Avatar>
          {file.type.startsWith("image")
            ? fileIcons.image
            : file.type.startsWith("video")
            ? fileIcons.video
            : fileIcons.other}
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        {file.path} - {formatBytes(file.size)}
      </ListItemText>
    </ListItem>
  ));

  const handleSubmit = async () => {
    setConnecting(true);
    let total = 0;
    let formData = new FormData();

    files.forEach((file) => {
      console.log("size ", file.size);
      total += file.size;
      formData.append("files", file);
    });
    console.log("total ", total);

    setTotalSize(total);

    axios
      .post(`${apiUrl}/uploadevidence/${caseId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          console.log("loaded ", event.loaded);
          setUploadedBytes(event.loaded);
        },
        cancelToken: source.token,
      })
      .then(() => {
        setUploadComplete(true);
      })
      .catch((err) => {
        console.log("Inner error ", err);
        if (err.toString() !== "Cancel") {
          props.dispatch(
            setSnackAction("Upload failed. Please try again.", "error")
          );
          setUploadedBytes(null);
          setFiles([]);
          setTotalSize(null);
          setUploadComplete(false);
          setConnecting(false);
        }
      });

    if (uploadedBytes > totalSize) setTotalSize(uploadedBytes);
  };

  const handleCancel = () => {
    source.cancel();
    setUploadedBytes(null);
    setFiles([]);
    setTotalSize(null);
    setUploadComplete(false);
    setSource(axios2.CancelToken.source());
    setConnecting(false);
  };

  useEffect(() => {
    if (uploadedBytes && connecting) setConnecting(false);
    uploadedBytesRef.current = uploadedBytes;
  }, [uploadedBytes, connecting]);

  if (isLoading)
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        <CircularProgress />
      </Box>
    );

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography variant="subtitle2">
          Please use the uploader below to attach the files associated with
          Case:
        </Typography>
        <Typography variant="subtitle2">
          {caseId} - Employee{employees.length > 1 && "s"}{" "}
          {employees.join(", ")}
        </Typography>

        <section style={{ marginTop: "24px" }}>
          <Container
            {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          >
            <input {...getInputProps()} />
            <CloudUpload fontSize="large" />
            <p style={{ cursor: "default" }}>Drag and drop your files here</p>
            <p style={{ cursor: "default" }}>
              or <span style={{ fontWeight: "bold" }}>click</span> to browse
            </p>
          </Container>
          <aside>
            <List>{fileList}</List>
          </aside>
          {uploadedBytes && totalSize && (
            <React.Fragment>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="subtitle2">
                  Upload{uploadComplete ? "ed" : "ing"}{" "}
                  {displayNthFile(files, uploadedBytes)} of {files.length}
                </Typography>
                <Typography variant="caption">
                  {Math.min(Math.floor((uploadedBytes / totalSize) * 100), 100)}
                  %
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={Math.min((uploadedBytes / totalSize) * 100, 100)}
              />
            </React.Fragment>
          )}
          <Box
            display="flex"
            justifyContent="center"
            style={{ marginTop: "24px" }}
          >
            {fileList.length > 0 && !uploadedBytes && !connecting && (
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            )}
            {fileList.length > 0 && !uploadedBytes && connecting && (
              <CircularProgress />
            )}
            {uploadedBytes && !uploadComplete && (
              <Button
                onClick={handleCancel}
                variant="contained"
                color="default"
              >
                Cancel
              </Button>
            )}
          </Box>
        </section>
      </Box>
    </React.Fragment>
  );
};

export default connect()(EvidenceUploader);
