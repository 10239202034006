import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store';

if(process.env.NODE_ENV !== "development")
  console.log(`Build ${process.env.REACT_APP_BUILD_NUMBER} (${process.env.REACT_APP_COMMIT_HASH})`)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));
