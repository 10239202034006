import ckcss from "!!raw-loader!../ckeditor/ck-content.css";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import axios from "../plugins/axios";
import MyCKEditor from "../ckeditor/MyCKEditor";
import "../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import MentionCard from "../ckeditor/MentionCard";
import {
  setMentionsAction,
  setAccountImagesAction,
} from "../redux/actions/configActions";
import { setSnackAction } from "../redux/actions/snackActions";
import AccountImageDialog from "./AccountImageDialog";
import { getNodesByType, setMentionValue } from "../ckeditor/ckEditorUtils";
import ImageBlock from "./ImageBlock";
import Icons from "../ckeditor/MentionIcons";
import {
  exportDocument,
  recordDocumentDownload,
  uploadDocumentVersion,
} from "../services/documentService";
import { downloadFileFromBlob } from "../utils/fileUtils";
import clsx from "clsx";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  LinearProgress,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import {
  GavelRounded as GavelIcon,
  Save as SaveIcon,
  GetApp as GetAppIcon,
} from "@material-ui/icons";
import { converterFileTypes } from "../constants/documentConstants";

const useStyles = makeStyles((theme) => ({
  rightComponent: {
    gridGap: theme.spacing(6),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  flex: {
    display: "flex",
    "&.column": {
      flexDirection: "column",
    },
    "&.spaceBetween": {
      justifyContent: "space-between",
    },
  },
}));

const apiUrl = process.env.REACT_APP_LDM_API_URL;

const DocumentManager = ({
  match,
  setSnack,
  dbMentions,
  setDbMentions,
  accountImages,
  setAccountImages,
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const { pid } = match.params;
  const [editor, setEditor] = useState(null);
  const [editorHtml, setEditorHtml] = useState();
  const [document, setDocument] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editImageKey, setEditImageKey] = useState(null);
  const [mentions, setMentions] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const headerRef = useRef();
  const footerRef = useRef();

  const setExpandedCheck = (val) => setExpanded(val === expanded ? false : val);

  const styles = { targetDiv: { height: "calc(100vh - 180px)" } };

  const editorChange = (html) => {
    setEditorHtml(html);
  };

  useEffect(() => {
    const getDocument = async () => {
      if (pid) {
        setIsLoading(true);
        var response = await axios.get(`${apiUrl}/getDocument/${pid}`);
        setDocument(response.data.document);
        setAccountImages(response.data.accountImages);
        setIsLoading(false);
      }
    };

    getDocument();
  }, [pid]);

  useEffect(() => {
    if (!document || !editor) return;

    let html = document.htmlContent;

    editor.setData(html);

    setEditorHtml(html);
  }, [document, editor]);

  useEffect(() => {
    if (dbMentions.length === 0) {
      const getDbMentions = async () => {
        var result = await axios.get(`${apiUrl}/getmentions`);
        setDbMentions(result.data);
      };
      getDbMentions();
    }
  }, [dbMentions, setDbMentions]);

  useEffect(() => {
    if (editor) {
      editor.model.change((writer) => {
        const root = editor.model.document.getRoot();
        const elements = getNodesByType(
          writer,
          ["blockplaceholder", "inlineplaceholder"],
          root
        );

        const ckmentions = elements.map((x) => {
          return {
            ...x.getAttribute("mention"),
            value: x.getAttribute("value") || "",
          };
        });

        setMentions(ckmentions);
      });
    }
  }, [editor, editorHtml, setMentions]);

  const progress = useMemo(() => {
    const total = mentions.length;

    if (total === 0) return 0;

    const completed = mentions.filter((m) => m.value);
    return (completed.length * 100) / total;
  }, [mentions]);

  const setValue = (key, value) => {
    setMentionValue(editor, key, value, setEditorHtml);
  };

  const saveClicked = async () => {
    if (editorHtml === document.htmlContent) {
      setSnack("Document hasn't changed since last save!", "error");
      return;
    }

    await save(editorHtml);
  };

  const save = async (html) => {
    setSaving(true);

    let updatedDocument = { ...document, htmlContent: html };
    var response = await axios.post(
      `${apiUrl}/savedocumentversion`,
      updatedDocument
    );
    updatedDocument.currentVersion = response.data;
    updatedDocument.versionUploaded = false;

    setDocument(updatedDocument);
    setSaving(false);
    return updatedDocument;
  };

  const imageInfo = async (accountImage, ref, blobStorageUrl = false) => {
    let info = {
      height: 25,
      html: "",
    };

    if (accountImage) {
      if (accountImage.imageDataUrl) {
        var img = new Image();
        img.src = accountImage.imageDataUrl;

        console.log("img", img.height, img.width);
        info.height = Math.floor((img.height * 700) / img.width) + 40;
        info.html = `<div class="ck-content"><figure class="image image_resized" style="width:100%;"><img src="${
          blobStorageUrl
            ? accountImage.blobStorageUrl
            : accountImage.imageDataUrl
        }"/></figure></div>`;
      }

      if (accountImage.htmlContent) {
        info.html = `<div class="ck-content">${accountImage.htmlContent}</div>`;
        info.height = ref.current.clientHeight;
      }
    }

    console.log("info", accountImage, info);
    return info;
  };

  const downloadPdf = async () => {
    let response;
    let doc = document;

    setDownloading(true);

    const [headerInfo, footerInfo] = await Promise.all([
      imageInfo(accountImages.DocumentHeader, headerRef),
      imageInfo(accountImages.DocumentFooter, footerRef),
    ]);

    let converterOptions = {
      margin_top: `${headerInfo.height}px`,
      margin_bottom: `${footerInfo.height}px`,
      margin_right: "15mm",
      margin_left: "15mm",
      header_and_footer_css: ckcss,
      header_html: headerInfo.html,
      footer_html: footerInfo.html,
    };

    try {
      response = await exportDocument({
        html: `<div class="ck-content">${editor.getData()}</div>`,
        css: ckcss,
        options: converterOptions,
        converterFileType: converterFileTypes.PDF,
      });
    } catch (err) {
      setSnack(err.message, "error");
      setDownloading(false);
      return;
    }

    if (editorHtml !== doc.htmlContent) {
      try {
        doc = await save(editorHtml);
      } catch (err) {
        setSnack(err.message, "error");
        setDownloading(false);
        return;
      }
    }

    const blob = new Blob([response.data]);

    if (!document.versionUploaded) {
      var formData = new FormData();
      formData.append("file", blob);
      formData.append("fileName", `${document.name}.pdf`);

      try {
        await uploadDocumentVersion({
          pid,
          currentVersion: document.currentVersion,
          formData,
        });
      } catch (err) {
        setSnack(err.message, "error");
        setDownloading(false);
        return;
      }

      setDocument({ ...doc, versionUploaded: true });
    }

    try {
      await recordDocumentDownload({
        identifier: pid,
        currentVersion: document.currentVersion,
      });
    } catch (err) {
      setSnack(err.message, "error");
      setDownloading(false);
      return;
    }

    downloadFileFromBlob({ bytes: blob, fileName: `${document.name}.pdf` });

    setDownloading(false);
  };

  const downloadDocx = async () => {
    let response;
    let doc = document;

    setDownloading(true);

    const [headerInfo, footerInfo] = await Promise.all([
      imageInfo(accountImages.DocumentHeader, headerRef, true),
      imageInfo(accountImages.DocumentFooter, footerRef, true),
    ]);

    let converterOptions = {
      margin_top: `${headerInfo.height}px`,
      margin_bottom: `${footerInfo.height}px`,
      margin_right: "15mm",
      margin_left: "15mm",
      header_and_footer_css: ckcss,
      header: headerInfo?.html ? [{ html: headerInfo.html }] : [],
      footer: footerInfo?.html ? [{ html: footerInfo.html }] : [],
    };

    try {
      [response, doc] = await Promise.all([
        exportDocument({
          html: `<div class="ck-content">${editor.getData()}</div>`,
          css: ckcss,
          options: converterOptions,
          converterFileType: converterFileTypes.DOCX,
        }),
        save(editorHtml),
      ]);
    } catch (err) {
      setSnack(err.message, "error");
      setDownloading(false);
      return;
    }

    const blob = new Blob([response.data]);

    if (!document.versionUploaded) {
      var formData = new FormData();
      formData.append("file", blob);
      formData.append("fileName", `${document.name}.docx`);

      try {
        await uploadDocumentVersion({
          pid,
          currentVersion: document.currentVersion,
          formData,
        });
      } catch (err) {
        setSnack(err.message, "error");
        setDownloading(false);
        return;
      }

      setDocument({ ...doc, versionUploaded: true });
    }

    try {
      await recordDocumentDownload({
        identifier: pid,
        currentVersion: document.currentVersion,
      });
    } catch (err) {
      setSnack(err.message, "error");
      setDownloading(false);
      return;
    }

    downloadFileFromBlob({ bytes: blob, fileName: `${document.name}.docx` });

    setDownloading(false);
  };

  if (isLoading) return <h1>Loading ...</h1>;

  if (!document) return <h1>No document found ...</h1>;

  const HelpBlock = () => {
    return (
      <Box display="flex" justifyContent="space-between" className="ew">
        <Box></Box>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ color: "#4eadde" }}
          >
            <Typography>Need help?</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Icons.Phone fontSize="small" />
            &nbsp;<Typography>0345 226 8393</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Grid container spacing={3} style={styles.targetDiv}>
      <Grid item xs={3} style={{ height: "100%" }}>
        <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
          <Box mb={3}>
            <Typography variant="h5">
              Please complete the steps below to complete your document
            </Typography>
          </Box>
          <Box mb={3}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Box flexGrow={1} style={{ height: 0 }}>
            <PerfectScrollbar>
              <Paper style={{ padding: "8px" }}>
                {mentions.map((m) => (
                  <MentionCard
                    key={m.key}
                    mention={m}
                    expanded={m.entityKey === expanded}
                    setExpanded={setExpandedCheck}
                    setValue={(value) => setValue(m.key, value)}
                  />
                ))}
              </Paper>
            </PerfectScrollbar>
          </Box>
        </Box>
      </Grid>
      <Grid item xs style={{ height: "100%" }}>
        <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
          <div className={classes.rightComponent}>
            <Button
              color="primary"
              variant="contained"
              disabled={downloading}
              onClick={downloadDocx}
            >
              Export to Word
            </Button>
            <div className={clsx(classes.flex, "column")}>
              <HelpBlock />
              <div className={clsx(classes.flex, "spaceBetween")}>
                <div className={classes.flex}>
                  <Typography>
                    Version {document.currentVersion} saved&nbsp;
                  </Typography>
                  {saving ? (
                    <CircularProgress style={{ marginLeft: "1px" }} size={21} />
                  ) : (
                    <Button
                      style={{ padding: "1px", minWidth: 0 }}
                      onClick={saveClicked}
                    >
                      <SaveIcon fontSize="small" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Box display="flex">
            <GavelIcon fontSize="small" />
            &nbsp;
            <Typography variant="h5">
              Case {document.caseId} -{" "}
              {document.employees.length === 1
                ? document.employees[0]
                : `${document.employees.length} Employees`}{" "}
              - {document.name}
            </Typography>
          </Box>
          {editImageKey && (
            <AccountImageDialog
              imageKey={editImageKey}
              existingImage={accountImages[editImageKey]}
              onClose={() => setEditImageKey(null)}
              documentIdentifier={pid}
            />
          )}
          <ImageBlock
            imageKey="DocumentHeader"
            onClick={() => setEditImageKey("DocumentHeader")}
            innerRef={headerRef}
          />
          <Box mb={2} flexGrow={1} style={{ height: 0 }} display="flex">
            <MyCKEditor
              setEditor={setEditor}
              onChange={editorChange}
              contained
              type="document"
            />
          </Box>
          <ImageBlock
            imageKey="DocumentFooter"
            onClick={() => setEditImageKey("DocumentFooter")}
            innerRef={footerRef}
          />
          {progress === 100 && (
            <Box display="flex" justifyContent="flex-end" className="ew">
              <Box>
                <Button disabled={downloading} onClick={downloadPdf}>
                  <GetAppIcon className="ew grow" fontSize="large" />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  dbMentions: state.configReducer.mentions,
  accountImages: state.configReducer.accountImages,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDbMentions: (mentions) => dispatch(setMentionsAction(mentions)),
    setSnack: (message, severity) =>
      dispatch(setSnackAction(message, severity)),
    setAccountImages: (images) => dispatch(setAccountImagesAction(images)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentManager);
