import axios from 'axios';


function errorResponseHandler(error) {
    // check for cancelled request
    if (axios.isCancel(error)) {
        return Promise.reject(error);
    }

    // check for errorHandle config
    if (error.config && error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
        return Promise.reject(error);
    }

    // if has response show the error
    if (error.response) {
        if (error.response.status === 401) {
            alert("Unauthorised");
            return;
        }
        if (error.response.status === 422) {
            alert(error.response.data);
            return;
        }
        
        return Promise.reject(error);
    }
    return Promise.reject(error);
}

const source = axios.CancelToken.source();
const _axios = axios.create();

_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

_axios.interceptors.response.use(
    response => response,
    errorResponseHandler
);

export default _axios;
export { source };