import { combineReducers } from 'redux';

import themeReducer from './themeReducer';
import userReducer from './userReducer';
import snackReducer from './snackReducer';
import configReducer from './configReducer';
import documentReducer from './documentReducer';

export default combineReducers({
	themeReducer,
	userReducer,
	snackReducer,
	configReducer,
	documentReducer
});
