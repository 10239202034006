import React, { useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Snackbar } from "@material-ui/core";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import maTheme from "./theme";
import { removeSnackAction } from "./redux/actions/snackActions";
import { setUsersAction } from "./redux/actions/userActions";
import { Alert } from "@material-ui/lab";
import "./custom.css";
import Routes from "./routes/Routes";
import axios from "./plugins/axios";

const apiUrl = process.env.REACT_APP_LDM_API_URL;

const App = ({ themeState, snackState, removeSnack, setUsers }) => {
  useEffect(() => {
    const getUsers = async () => {
      var response = await axios.get(`${apiUrl}/getusers`);
      setUsers(response.data);
    };

    getUsers();
  });

  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | Worknest LDM" defaultTitle="Worknest LDM" />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[themeState.currentTheme]}>
            <ThemeProvider theme={maTheme[themeState.currentTheme]}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
        {snackState.snack.message && (
          <Snackbar
            open={snackState.open}
            autoHideDuration={6000}
            onClose={removeSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={removeSnack} severity={snackState.snack.severity}>
              {snackState.snack.message}
            </Alert>
          </Snackbar>
        )}
      </StylesProvider>
    </React.Fragment>
  );
};

const mapStateToProps = (store) => ({
  themeState: store.themeReducer,
  snackState: store.snackReducer,
});
const mapDispatchToProps = (dispatch) => {
  return {
    removeSnack: () => dispatch(removeSnackAction()),
    setUsers: (users) => dispatch(setUsersAction(users)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
