import * as types from '../constants';

export function setMentionsAction(mentions) {
    return {
        type: types.SET_MENTIONS,
        mentions
    }
}


export function setAccountImagesAction(images) {
    return {
        type: types.SET_ACCOUNT_IMAGES,
        images
    }
}

