import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes } from "./index";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";
import Page404 from "../components/auth/Page404";
import {
    Box
} from "@material-ui/core";

const childRoutes = (Layout, routes) =>
    routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
                // Route item without children
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            )
    );

const Routes = () => (

    <Box display="flex">
        <Router>
            <Box flexGrow={1}>
                <Switch>
                    {childRoutes(DashboardLayout, dashboardRoutes)}                  
                    <Route
                        render={() => (
                            <AuthLayout>
                                <Page404 />
                            </AuthLayout>
                        )}
                    />
                </Switch>
            </Box>         
        </Router>
    </Box>
);


export default Routes;
