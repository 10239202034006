import * as types from '../constants';

const initialState = {
    activeMention: null
};

const reducer = (state, action) => {
    state = state || initialState;  
    
    if (action.type === types.SET_ACTIVE_MENTION) {       
        return {
            ...state,
            activeMention: action.mention
        };
    }   

    return state;
};

export default reducer;