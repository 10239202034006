import * as types from '../constants';

export function setActiveMentionAction(mention) {
    return {
        type: types.SET_ACTIVE_MENTION,
        mention: mention
    }
}


