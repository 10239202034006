import * as types from "../constants";
import _ from "lodash";
import axios from "../../plugins/axios";

const initialState = {
  mentions: [],
  caseTypes: {},
  accountImages: null,
};

const apiUrl = process.env.REACT_APP_LDM_API_URL;

export const actionCreators = {
  setAccountImage:
    (documentIdentifier, imageType, imageDataUrl, htmlContent, file) =>
    async (dispatch) => {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("imageType", imageType);
      imageDataUrl && formData.append("imageDataUrl", imageDataUrl);
      htmlContent && formData.append("htmlContent", htmlContent);

      const result = await axios.post(
        `${apiUrl}/setaccountimage/${documentIdentifier}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const blobStorageUrl = result?.data?.result;
      dispatch({
        type: types.SET_ACCOUNT_IMAGE,
        payload: {
          imageType,
          imageDataUrl,
          htmlContent,
          blobStorageUrl,
        },
      });
    },
};

const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === types.SET_MENTIONS) {
    return {
      ...state,
      mentions: action.mentions,
    };
  }

  if (action.type === types.SET_ACCOUNT_IMAGES) {
    return {
      ...state,
      accountImages: _.keyBy(action.images, (i) => i.imageType),
    };
  }

  if (action.type === types.SET_ACCOUNT_IMAGE) {
    let info = action.payload;

    return {
      ...state,
      accountImages: {
        ...state.accountImages,
        [info.imageType]: info.imageDataUrl || info.htmlContent ? info : null,
      },
    };
  }

  return state;
};

export default reducer;
