// config reducer
export const SET_MENTIONS = 'SET_MENTIONS';
export const SET_ACCOUNT_IMAGES = 'SET_ACCOUNT_IMAGES';
export const SET_ACCOUNT_IMAGE = 'SET_ACCOUNT_IMAGE';

// document reducer
export const SET_ACTIVE_MENTION = 'SET_ACTIVE_MENTION';

// snack reducer
export const SET_SNACK = 'SET_SNACK';
export const REMOVE_SNACK = 'REMOVE_SNACK';

// theme reducer
export const SET_THEME = 'SET_THEME';

// user reducer
export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';








