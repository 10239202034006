import { v4 as uuidv4 } from 'uuid';

export const getNodesByType = function (writer, type, root) {
    const nodes = [];
    const range = writer.createRangeIn(root);

    for (const value of range.getWalker({ ignoreElementEnd: true })) {
        const node = value.item;

        if (Array.isArray(type)) {
            if (type.includes(node.name)) {
                nodes.push(node);
            }
        }
        else {
            if (node.name === type) {
                nodes.push(node);
            }
        }        
    }
    
    return nodes;
};

export const setMentionValue = (editor, key, value, setEditorHtml) =>  {

    editor.model.change(writer => {

        const root = editor.model.document.getRoot();
        const elements = getNodesByType(writer, ['blockplaceholder', 'inlineplaceholder'], root);
        for (const element of elements) {
            const mention = element.getAttribute('mention');
           
            if (mention.key == key) {
                mention.value = value;
                writer.setAttribute('value', value, element);
            }
        }
        setEditorHtml(editor.getData());
    });
};


const entityStyleFn = entity => {
    const entityType = entity.get('type').toLowerCase();
    if (entityType === 'mention') {
        const mention = entity.getData().mention;
        console.log("mention entity", mention);
        let elementType;
        let style;
        switch (mention.type) {
            case "list":
                elementType = "ul";
                style = "border-radius: 2px;background-color:#F0F8FF;height: 50px;white-space:pre;";
                break;
            case "textarea":
                elementType = "div";
                style = "border-radius: 2px;background-color:#F0F8FF;height: 50px;";
                break;
            default:
                elementType = "span";
                style = "border-radius: 2px; background-color: #F0F8FF;";
                break;
        }
      

        let attributes = {
            class: 'placeholder',
            'data-id': "@" + mention.name,
            'data-mention-id': mention.mentionId,
            'data-type': mention.type,
            'data-name': mention.name,
            'data-key': uuidv4(),
            'data-value': '',
            title: mention.guidance,
            style
        };

        if (mention.type === "option")
            attributes = { ...attributes, 'data-options': JSON.stringify(mention.options) };

        return {
            element: elementType,
            attributes
        };
    }
}