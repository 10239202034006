import { blue, green, grey, indigo, red, teal } from "@material-ui/core/colors";

const blueVariant = {
  name: "Blue",
  palette: {
    primary: {
      main: blue[800],
      contrastText: "#FFF"
    },
    secondary: {
      main: blue[600],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: blue[600]
    }
  },
  sidebar: {
    color: "#FFF",
    background: blue[700],
    header: {
      color: "#FFF",
      background: blue[800],
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: blue[800],
      online: {
        background: "#FFF"
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: "#F7F9FC"
  }
};

const greenVariant = {
    name: "Green",
    palette: {
        primary: {
            //main: green[800],
            main: "#3A7745",
            contrastText: "#FFF"
        },
        secondary: {
            //main: green[500],
            main: "#b7d02e",
            contrastText: "#FFF"
        }
    },
    header: {
        color: grey[500],
        background: "#FFF",
        search: {
            color: grey[800]
        },
        indicator: {
            //background: green[500]
            background: "#b7d02e"
        }
    },
    sidebar: {
        //color: "#FFF",
        //color: "#FCFCF1",
        color: "#FFFFFF",
        //background: green[700],
        background: "rgb(68,136,79)",
        header: {
            //color: "#FFF",
            color: "#FFFFFF",
            //background: green[800],
            background: "#b7d02e",
            brand: {
                color: "#FFFFFF"
            }
        },
        footer: {
            color: "#FFF",
            //background: green[800],
            background: "#3A7745",
            online: {
                background: "#FFF"
            }
        },
        category: {
            fontWeight: 400
        },
        badge: {
            //color: "#000",
            color: "#FCFCF1",
            background: "#FFF"
        }
    },
    body: {
        background: "#F9F9FC"
    }
};

const indigoVariant = {
  name: "Indigo",
  palette: {
    primary: {
      main: indigo[600],
      contrastText: "#FFF"
    },
    secondary: {
      main: indigo[400],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: indigo[500]
    }
  },
  sidebar: {
    color: "#FFF",
    background: indigo[600],
    header: {
      color: "#FFF",
      background: indigo[700],
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: indigo[700],
      online: {
        background: "#FFF"
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: "#F9F9FC"
  }
};

const tealVariant = {
  name: "Teal",
  palette: {
    primary: {
      main: teal[800],
      contrastText: "#FFF"
    },
    secondary: {
      main: teal[600],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: teal[600]
    }
  },
  sidebar: {
    color: "#FFF",
    background: teal[700],
    header: {
      color: "#FFF",
      background: teal[800],
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: teal[800],
      online: {
        background: "#FFF"
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: "#F7F9FC"
  }
};

const lightVariant = {
  name: "Light",
  palette: {
    primary: {
      main: blue[800],
      contrastText: "#FFF"
    },
    secondary: {
      main: blue[600],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[200],
    background: blue[800],
    search: {
      color: grey[100]
    },
    indicator: {
      background: red[700]
    }
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: blue[800],
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: grey[900],
      background: grey[100],
      online: {
        background: green[500]
      }
    },
    category: {
      fontWeight: 600
    },
    badge: {
      color: "#FFF",
      background: green[600]
    }
  },
  body: {
    background: "#F7F9FC"
  }
};

const darkVariant = {
    name: "Dark",
    palette: {
        primary: {
            main: "#551A8B",
            contrastText: "#FFF"
        },
        secondary: {
            main: "#4cacde",
            contrastText: "#FFF"
        },
        teal: {
            main: "#216869",
            contrastText: "#FFF"
        }
    },
    header: {
        color: "#FFF",
        background: "#551A8B",
        search: {
            color: "#FFF"
        },
        indicator: {
            background: blue[600]
        }
    },
    sidebar: {
        color: grey[200],
        background: "#254977",
        header: {
            color: grey[200],
            background: "#551A8B",
            brand: {
                color: blue[500]
            }
        },
        footer: {
            color: grey[200],
            background: "#551A8B",
            online: {
                background: green[500]
            }
        },
        category: {
            fontWeight: 400
        },
        badge: {
            color: "#FFF",
            background: blue[500]
        }
    },
    body: {
        background: "#F7F9FC"
    },
    section: {
        background: green[500]
    }
};

const variants = [
  darkVariant,
  lightVariant,
  blueVariant,
  greenVariant,
  indigoVariant,
  tealVariant
];

export default variants;
