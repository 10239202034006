import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { makeStyles } from '@material-ui/core/styles';
import { actionCreators as configActionCreators } from '../redux/reducers/configReducer';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import MyCKEditor from "../ckeditor/MyCKEditor";

import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Tooltip
} from "@material-ui/core";

import {
    ToggleButton,
    ToggleButtonGroup,
    Alert
} from "@material-ui/lab";

import {
    CloudUpload as CloudUploadIcon,
    Close as CloseIcon
} from "@material-ui/icons";

const useStyles = makeStyles({
    dialog: {
        height: 'calc(100% - 64px)'
    }
});

const AccountImageDialog = ({ imageKey, existingImage, onClose, setAccountImage, documentIdentifier }) => {

    const [image, setImage] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [croppedImageUrl, setCroppedImageUrl] = useState();
    const [imageFile, setImageFile] = useState(null)
    const classes = useStyles();    
    const [crop, setCrop] = useState();
    const [message, setMessage] = useState();
    const [method, setMethod] = useState(existingImage?.htmlContent ? 'Design' : 'Image')
    const [editor, setEditor] = useState();

    useEffect(() => {       

        if (editor)
            editor.setData(existingImage?.htmlContent || "");     

    }, [editor]);

    const onDrop = async acceptedFiles => {

        if (acceptedFiles.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImageUrl(reader.result);
                setCroppedImageUrl();
                setCrop({});
                setMessage("Save changes now or crop image with mouse.");
            });
            reader.readAsDataURL(acceptedFiles[0]);
            setImageFile(acceptedFiles[0])
        }
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });    

    const onCropComplete = async (crop) => {
        if (image && crop.width && crop.height) {
            const url = await getCroppedImg(image, crop);
            setCroppedImageUrl(url);
            setMessage("Save changes to upload cropped image.");
        }
    }

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return canvas.toDataURL();
    }

    const save = () => {
        setAccountImage(documentIdentifier, 
            imageKey,
            method === "Image" ?  (croppedImageUrl || imageUrl) : null,
            method === "Design" ? editor.getData() : null, 
            imageFile);
        onClose();
    }

    const removeImage = () => {
        setAccountImage(documentIdentifier, 
            imageKey,
            null,
            null,
            null);
        onClose();
    }

    const handleMethodChange = (e, newMethod) => {
        if (newMethod != null)
            setMethod(newMethod);
    };

    return (
        <Dialog disableEnforceFocus open={!!imageKey} fullWidth maxWidth="lg" classes={{ paper: classes.dialog }}>
            <DialogTitle onClose={onClose}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h2">Manage Account Image ({imageKey}) ({method})</Typography>
                    {existingImage && <Button variant="contained" size="small" onClick={removeImage}>Remove Image</Button>}
                    <ToggleButtonGroup color="primary" value={method} exclusive onChange={handleMethodChange}>
                        <ToggleButton value="Image">Image</ToggleButton>
                        <ToggleButton value="Design">Design</ToggleButton>                        
                    </ToggleButtonGroup>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>                
            </DialogTitle>
           
            <DialogContent>
                {
                    method === "Image" &&
                    <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '150px', border: '1px solid purple' }}>
                            {
                                existingImage?.imageDataUrl
                                    ? <Tooltip title="Existing Image"><img style={{ maxWidth: '100%', maxHeight: '150px' }} src={existingImage.imageDataUrl} /></Tooltip>
                                    : <Typography variant="h6">No existing image</Typography>
                            }
                        </Box>
                        <section>
                            <Box display="flex" flexDirection="column"  {...getRootProps()} style={{ padding: '5px' }}>
                                <input {...getInputProps()} accept="image/*" />
                                <Box display="flex" justifyContent="center">
                                    <CloudUploadIcon style={{ marginRight: '5px' }} />
                                    <Typography style={{ cursor: 'default' }}>Drag and drop your image here or <strong>click</strong> to browse</Typography>
                                </Box>
                            </Box>
                        </section>
                        {message && <Alert severity="info">{message}</Alert>}
                        <Box flexGrow={1} style={{ height: 0 }} display="flex">
                            <Box width="50%" display="flex" flexDirection="column">
                                <Box display="flex" justifyContent="center"><Typography>Uploaded Image</Typography></Box>
                                <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1} style={{ height: 0, maxHeight: '100%' }}>
                                    <ReactCrop
                                        src={imageUrl}
                                        style={{}}
                                        imageStyle={{}}
                                        crop={crop}
                                        onChange={newCrop => setCrop(newCrop)}
                                        onComplete={onCropComplete}
                                        onImageLoaded={image => setImage(image)}
                                    />
                                </Box>
                            </Box>
                            <Box width="50%" display="flex" flexDirection="column" >
                                <Box display="flex" justifyContent="center"><Typography>Cropped Image</Typography></Box>
                                <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center" style={{ height: 0 }}>
                                    {croppedImageUrl && <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={croppedImageUrl} />}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }
                {
                    method === "Design" && <div style={{ width: '100%', overflow: 'hidden' }}><MyCKEditor setEditor={setEditor} type="plain"/></div>                   
                }
            </DialogContent>
            {
                (imageUrl && method === "Image" || (method === "Design")) &&
                <DialogActions>
                    <Button autoFocus onClick={save} color="primary" variant="contained">Save changes</Button>
                </DialogActions>
            }
        </Dialog>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(configActionCreators, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(AccountImageDialog);

