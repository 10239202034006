import * as types from '../constants';
import axios from "../../plugins/axios";
import _ from 'lodash';


const initialState = {
    success: false,
    token: "",
    tokenExpiry: null,
    message: null,
    username: "",
    displayName: "",    
    users: []
};


const reducer = (state, action) => {
    state = state || initialState;  

    if (action.type === types.SET_USER) {
        localStorage.setItem('userInfo', JSON.stringify(action.user));
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + action.user.token; 
        return {
            ...state,
            success: action.user.success,
            token: action.user.token,
            tokenExpiry: action.user.tokenExpiry,
            message: action.user.message,
            username: action.user.username,
            displayName: action.user.displayName
        }
    }    

    if (action.type === types.SET_USERS) {
        return {
            ...state,
            users: action.users
        }
    }
   
    return state;
};

export default reducer;