import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import moment from "moment";

export default class InlinePlaceholder extends Plugin {

    static get requires() {
        return [Widget];
    }

    init() {        

        this._defineSchema();
        this._defineConverters();

        this.editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('placeholder'))
        );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('inlineplaceholder', {
            allowWhere: '$text',
            isInline: true,
            isObject: true,
            allowAttributesOf: '$text',
            allowAttributes: ['mention','value']
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        const converter = (viewElement, { writer }) => {
            let mention = {
                guidance: viewElement.getAttribute('title') || "",
                id: viewElement.getAttribute('data-id'),
                mentionId: parseInt(viewElement.getAttribute('data-mention-id')),
                name: viewElement.getAttribute('data-name'),
                type: viewElement.getAttribute('data-type'),
                key: viewElement.getAttribute('data-key')                
            };

            if (mention.type === "option") {
                let optionsString = viewElement.getAttribute('data-options');
                mention = { ...mention, options: optionsString ? JSON.parse(optionsString) : []}
            }
            const value = viewElement.getAttribute('data-value') || "";            

            return writer.createElement('inlineplaceholder', { mention, value });
        }

        conversion.for('upcast').elementToElement({
            view: {
                name: 'span',
                classes: ['placeholder']
            },
            model: converter
        });

        conversion.for('editingDowncast')
            .elementToElement({
                model: 'inlineplaceholder',
                view: (modelItem, { writer: viewWriter }) => {
                    const widgetElement = createPlaceholderView(modelItem, viewWriter);

                    return toWidget(widgetElement, viewWriter);
                },
                triggerBy: {
                    attributes: ['value']
                }
            });

        conversion.for('dataDowncast').elementToElement({
            model: 'inlineplaceholder',
            view: (modelItem, { writer }) => createPlaceholderView(modelItem, writer)            
        });

        function createPlaceholderView(modelItem, writer) {
            let mention = modelItem.getAttribute('mention');
            let value = modelItem.getAttribute('value');            

            let attributes = {
                class: 'placeholder',
                'data-id': mention.id,
                'data-mention-id': mention.mentionId,
                'data-type': mention.type,
                'data-name': mention.name,
                'data-key': mention.key,
                'data-value': value || "",
                title: mention.guidance || "",
                style: !value && "border-radius: 2px; background-color: #F0F8FF"
            };

            if (mention.type === "option")
                attributes = { ...attributes, 'data-options': JSON.stringify(mention.options) };            

            const placeholderView = writer.createContainerElement('span', attributes,
            {
                isAllowedInsideAttributeElement: false
            });

            if (value && mention.type === "date")
                value = moment(new Date(value)).format("DD/MM/YYYY");

            // Insert the placeholder name (as a text).
            const innerText = writer.createText(value || mention.name);
            writer.insert(writer.createPositionAt(placeholderView, 0), innerText);

            return placeholderView;
        }
    }
}