import axios from "axios";
import { converterUrls } from "../constants/documentConstants";

const apiUrl = process.env.REACT_APP_LDM_API_URL;

export async function exportDocument({
  html,
  css,
  options,
  converterFileType,
}) {
  return await axios.post(
    converterUrls[converterFileType],
    {
      html,
      css,
      options,
    },
    { responseType: "blob" }
  );
}

export async function uploadDocumentVersion({ pid, currentVersion, formData }) {
  await axios.post(
    `${apiUrl}/uploaddocumentversion/${pid}/${currentVersion}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export async function recordDocumentDownload({ identifier, currentVersion }) {
  await axios.post(`${apiUrl}/recorddocumentdownload`, {
    identifier,
    currentVersion,
  });
}
