import * as types from '../constants';

const initialState = {
    snack: {},
    open: false
};

const reducer = (state, action) => {
    state = state || initialState;  
    
    if (action.type === types.SET_SNACK) {       
        return {
            ...state,
            snack: action.snack,
            open: true
        };
    }   

    if (action.type === types.REMOVE_SNACK) {
        return {
            ...state,
            snack: {},
            open: false
        };
    }  
    return state;
};

export default reducer;