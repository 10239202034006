import React from "react";
import {
    Home as HomeIcon,   
} from "react-feather";

import DocumentManager from "../components/DocumentManager";
import Blank from "../components/Blank";
import EvidenceUploader from "../components/EvidenceUploader";

const homeRoutes = {
    id: "Home",
    path: "/",
    icon: <HomeIcon />,
    component: Blank
}

// This route is not visible in the sidebar
const privateRoutes = {
    id: "Private",
    path: "/private",
    component: Blank,
    children: [
        {
            id: "Document",
            path: "/document/:pid",
            component: DocumentManager
        },
        {
            path: "/evidenceuploader/:caseGuid",
            name: "EvidenceUploader",
            component: EvidenceUploader
        },      
    ]
};

// routes with dashboard layout
export const dashboard = [
    homeRoutes,
    privateRoutes,    
];

// visible Menu
export default [
    homeRoutes,    
];
