import * as types from '../constants';

export function setUserAction(user) {
  return {
    type: types.SET_USER,
    user: user
  }
}

export function setUsersAction(users) {
    return {
        type: types.SET_USERS,
        users: users
    }
}