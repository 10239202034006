import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { setUserAction } from '../redux/actions/userActions';

import {
    Grid,
    Hidden,
    Menu,
    MenuItem,
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
    Toolbar,

    Typography
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import {
    Power
} from "react-feather";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Header = ({ onDrawerToggle, user, setUser }) => {     

    const [anchorMenu, setAnchorMenu] = useState(null);

    const toggleMenu = event => { setAnchorMenu(event.currentTarget) };

    const closeMenu = () => { setAnchorMenu(null) };

    const open = !!anchorMenu;

    const signOut = () => {
        closeMenu();
        setUser({username: user.username});
    };

    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Hidden mdUp>
                            <Grid item>
                                <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>                        
                        <Grid item><Typography variant="h3">CaseNest Document Manager</Typography></Grid>
                        <Grid item xs />
                        {false && <Grid item>
                            <IconButton aria-owns={open ? "menu-appbar" : undefined} aria-haspopup="true" onClick={toggleMenu} color="inherit">
                                <Power />
                            </IconButton>
                            <Menu id="menu-appbar" anchorEl={anchorMenu} open={open} onClose={closeMenu}>
                                <MenuItem onClick={signOut}>Sign Out</MenuItem>
                            </Menu>
                        </Grid>}
                    </Grid>
                </Toolbar>
            </AppBar>            
        </React.Fragment>
    );
}

const mapStateToProps = store => ({ user: store.userReducer })

const mapDispatchToProps = dispatch => {
    return {        
        setUser: (user) => dispatch(setUserAction(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));
